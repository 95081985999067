'use client';

import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { useRouter } from '@contexts/Router/RouterContext';
import {
  AnnouncementBarFragment,
  AnnouncementFragment,
} from '@graphql/generated-contentful/graphql';
import { useCountdown } from '@lib/hooks/useCountdown';
import { AnnouncementBar } from '@ui-components/index';
import { Duration, isPast } from 'date-fns';
import { DEFAULT_LOCALE, pluralize } from 'utils';

const useFormatCountdown = (
  locale: string,
  countdownDuration: Duration | null,
  announcementBar: AnnouncementBarFragment,
  announcement?: AnnouncementFragment | null
) => {
  if (
    !countdownDuration ||
    !announcement?.countdown ||
    isPast(announcement.countdown)
  ) {
    return null;
  }

  const days = countdownDuration.days ?? 0;
  const hours = countdownDuration.hours ?? 0;
  // Add 1 minute since we don't show seconds, keeps the countdown from showing 0 minutes
  const minutes = (countdownDuration.minutes ?? 0) + 1;
  let countdown = '';
  countdown += `${days} ${pluralize('day', announcementBar, days, locale)} `;
  countdown += `${hours} ${pluralize('hour', announcementBar, hours, locale)} `;
  countdown += `${minutes} ${pluralize('minute', announcementBar, minutes, locale)} `;
  countdown += ` ${announcementBar.left}`;
  return countdown;
};

type AnnouncementBarWrapperProps = {
  announcementBar: AnnouncementBarFragment;
  data?: AnnouncementFragment | null;
};

export const AnnouncementBarWrapper = ({
  announcementBar,
  data: announcement,
}: AnnouncementBarWrapperProps) => {
  const router = useRouter();
  const { locale } = router;
  const countdownDuration = useCountdown(announcement?.countdown);
  const countdown = useFormatCountdown(
    locale ?? DEFAULT_LOCALE,
    countdownDuration,
    announcementBar,
    announcement
  );

  return (
    <AnnouncementBar
      data={{
        html: documentToHtmlString(announcement?.content?.json),
        countdown,
        destinationUrl: announcement?.destinationUrl,
      }}
    />
  );
};
